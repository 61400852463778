<template>
  <div>

    <b-row>
      <b-col
        xxl="6"
        xl="6"
        lg="6"
        md="12"
        xs="12"
      >
        <medal-card />
      </b-col>
    </b-row>

    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col
        v-for="item in itemLocal.counting"
        :key="item.index"
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          :icon="item.icon"
          :statistic="item.statistic"
          :statistic-title="item.title"
          :color="item.color"
        />
      </b-col>

    </b-row>

    <b-row class="match-height">
      <b-col cols="6">
        <general-table-card :rows="itemLocal.general" />
      </b-col>
      <b-col cols="6">
        <general-chart-card />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <report-detail-card :rows="itemLocal.detail" />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue';

// eslint-disable-next-line import/no-cycle
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';

import MedalCard from '@/views/dashboard/items/MedalCard.vue';
import useReportProperty from './useReportProperty';
import GeneralTableCard from './GeneralTableCard.vue';
import GeneralChartCard from './GeneralChartCard.vue';
import ReportDetailCard from './ReportDetailCard.vue';

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
    GeneralTableCard,
    GeneralChartCard,
    ReportDetailCard,
    MedalCard,
  },
  setup() {
    const {
      fetchData, itemLocal,
    } = useReportProperty();

    return {
      fetchData,
      itemLocal,
    };
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  .apartment-list-container {
    .vgt-table {
        thead {
          tr {
            th {
              min-width: 120px !important;

              &:first-child {
                min-width: 50px !important;
              }

              &:nth-child(4) {
                 min-width: 300px !important;
              }
            }
          }
        }
      }
  }
  </style>
