import store from '@/store';
import toast from '@/utils/toast';
import { ref } from '@vue/composition-api';

export default function useReportProperty() {
  // Use toast
  const toastification = toast();

  const itemLocal = ref({
    counting: {},
    general: [],
    detail: [],
  });

  //   API Call

  const getReportPropertyCounting = () => {
    store.dispatch('report/getReportPropertyCounting').then(response => {
      itemLocal.value.counting = response.data;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const getReportPropertyGeneral = () => {
    store.dispatch('report/getReportPropertyGeneral').then(response => {
      itemLocal.value.general = response.data;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const getReportPropertyDetail = () => {
    store.dispatch('report/getReportPropertyDetail').then(response => {
      itemLocal.value.detail = response.data;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const fetchData = () => {
    getReportPropertyGeneral();
    getReportPropertyCounting();
    getReportPropertyDetail();
  };

  return {
    itemLocal,
    fetchData,
  };
}
